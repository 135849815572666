//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from "vue"
import IdleVue from "idle-vue"
import Vuex from "vuex"
import VueCookies from "vue-cookies"
import NaviBar from "~/components/NaviBar"
import { SidebarMenu } from "vue-sidebar-menu"

Vue.use(Vuex)

const eventsHub = new Vue()
const store = new Vuex.Store()

Vue.use(VueCookies)
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: parseInt(window.__NUXT__.config.sessionTime) * 60 * 1000
})

export default {
  components: {
    NaviBar,
    SidebarMenu
  },
  asyncData({ $config }) {
    return $config
  },
  data() {
    return {
      timerId: null,
      sideWidth: "245px",
      collapsed: false,
      menu: [
        {
          href: "/dashboard",
          title: "HOME",
          icon: "dashboard"
        },
        {
          href: "/schedule/my",
          title: "스케줄",
          icon: "schedule",
          alias: [
            "/schedule/all#DaySchedule",
            "/schedule/all#WeekSchedule",
            "/schedule/all#MonthSchedule"
          ],
          child: [
            {
              href: "/schedule/my",
              title: "내 스케줄"
            },
            {
              href: "/schedule/all",
              title: "동료 스케줄",
              class:
                this.$auth.loggedIn === true &&
                this.$auth.user.acr.user_site.pages.colleague_schedule
                  .accessible === true
                  ? ""
                  : "menu-hidden",
              alias: ["#DaySchedule", "#WeekSchedule", "#MonthSchedule"]
            }
          ]
        },
        {
          href: "/workingattitude",
          title: "근태/근로",
          icon: "workingattitude"
        },
        {
          href: "/leave/status",
          title: "휴가",
          icon: "leave",
          alias: [
            "/leave/plan#MyPlan",
            "/leave/plan#ApprovalPlan",
            "/leave/plan#ReferencePlan"
          ],
          child: [
            {
              href: "/leave/status",
              title: "휴가 현황"
            },
            {
              href: "/leave/plan",
              title: "연차사용촉진",
              class:
                this.$auth.loggedIn === true &&
                this.$auth.user.acr.user_site.pages.encourage_leave
                  .accessible === true
                  ? ""
                  : "menu-hidden",
              alias: ["#MyPlan", "#ApprovalPlan", "#ReferencePlan"]
            }
          ]
        },
        {
          href: "/approval/drafting",
          title: "결재",
          icon: "approval",
          child: [
            {
              href: "/approval/drafting",
              title: "기안문서함"
            },
            {
              href: "/approval/approval",
              title: "결재문서함"
            },
            {
              href: "/approval/reference",
              title: "참조문서함"
            }
          ]
        },
        {
          href: "/notice",
          title: "공지사항",
          icon: "notice"
        }
      ]
    }
  },
  computed: {
    marginContent: function() {
      return this.collapsed ? "73px" : this.$auth.loggedIn ? "245px" : "0px"
    }
  },
  watch: {
    isAppIdle(val) {
      val === true
        ? Vue.$cookies.set("idle", true)
        : Vue.$cookies.set("idle", false)
    }
  },
  async beforeMount() {
    Vue.$cookies.set("idle", false)

    document.addEventListener("click", () => {
      Vue.$cookies.set("idle", false)
    })

    document.addEventListener("keydown", () => {
      Vue.$cookies.set("idle", false)
    })

    this.timerId = setInterval(() => {
      this.idleLogout()
    }, 1000)

    await this.$auth.fetchUser() // 새로고침시 사용자 정보 재로드

    if (this.$store.state.loadCheck) {
      await this.$store.dispatch("getCompany")
      const year = this.$moment().format("YYYY")
      await this.$store.dispatch("getHolidays", year) // 휴일로드
      await this.$store.dispatch("getEmployees") // 사용자
      await this.$store.dispatch("getDepartments") // 부서 조직도
    }

    this.$store.dispatch("getLoadCheck", true)
  },
  methods: {
    async idleLogout() {
      if (this.$auth.loggedIn && Vue.$cookies.get("idle") === "true") {
        await this.$store.commit(
          "logoutMessage",
          "장시간 사용하지 않아 연결이 종료되었습니다."
        )
        await this.$store.commit("logoutModal", true)
        await this.$store.dispatch("logout")
        clearInterval(this.timerId)
      }
    },
    onCollapse(collapsed) {
      this.collapsed = collapsed
      window.$(".contents-area").css("marginLeft", "250px")
    },
    onItemClick(event, item) {
      if (item.href !== undefined) {
        window.$("#navbarCollapse").removeClass("show")
      }
    }
  }
}
