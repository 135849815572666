import Vue from "vue"
import VueSweetalert2 from "vue-sweetalert2"
window._ = require("lodash")

Vue.use(VueSweetalert2)

export default function({ $axios, store, app }) {
  $axios.onResponseError(async error => {
    const code = parseInt(error.response && error.response.status)
    let message

    if (code === 419 || code === 410) {
      if (
        error.request.responseType === "blob" &&
        error.response.data instanceof Blob &&
        error.response.data.type &&
        error.response.data.type.toLowerCase().indexOf("json") != -1
      ) {
        const reader = new FileReader()
        reader.addEventListener("loadend", () => {
          const text = JSON.parse(reader.result)
          store.commit("logoutMessage", text.message)
        })
        reader.readAsText(error.response.data)
      } else {
        store.commit("logoutMessage", error.response.data.message)
      }

      store.commit("logoutModal", true)

      if (app.$auth.loggedIn) {
        await store.dispatch("logout")
      }
    } else {
      if (error.response === undefined) {
        return false
      }

      if (code === 500) {
        message = `서버와의 통신에 실패하였습니다. : ${code}`
      } else {
        if (error.response.data.errors !== undefined) {
          message = `${
            window._.isObject(error.response.data.errors)
              ? window._.values(error.response.data.errors)[0].join("\n")
              : error.response.data.errors
          }`
        } else {
          message = `${error.response.data.message}`
        }
      }

      await Vue.swal({
        html: `<h2 class="font-icon icon-unauth color-danger mb-2"></h2>${window._.replace(
          message,
          /\n/g,
          "<br />"
        )}`,
        width: 500
      })
    }
    return Promise.reject(error)
  })
}
