export const strict = false
import createPersistedState from "vuex-persistedstate"
window._ = require("lodash")

export const state = () => ({
  logoutModal: false,
  logoutMessage: "",
  docs: [],
  employees: [],
  departments: [],
  approvalLines: [],
  holidays: [],
  loadCheck: false
})

export const mutations = {
  setCompany(state, value) {
    state.company = value
  },
  logoutModal(state, status) {
    state.logoutModal = status
  },
  logoutMessage(state, text) {
    state.logoutMessage = text
  },
  setUser(state, value) {
    state.user = value
  },
  setDocs(state, value) {
    state.docs = value
  },
  setEmpolyees(state, value) {
    state.employees = value
  },
  setDepartments(state, value) {
    state.departments = value
  },
  setApprovalLines(state, value) {
    state.approvalLines = value
  },
  setHolidays(state, value) {
    state.holidays = value
  },
  setLoadCheck(state, value) {
    state.loadCheck = value
  }
}

export const actions = {
  logout: window._.debounce(async function({ state }) {
    await window.localStorage.removeItem("vuex")
    await this.$auth.logout()
    window.$(".modal").modal("hide")

    if (state.logoutModal === false) {
      this.$router.go({ path: "/login" })
    }
  }, 1000),

  getCompany({ state, commit }) {
    if (this.$auth.loggedIn) {
      commit("setCompany", state.auth.user.user.company_id)
    }
  },

  getUser({ state, commit }) {
    if (this.$auth.loggedIn) {
      commit("setUser", state.auth.user.user.id)
    }
  },
  async getDocs({ commit }) {
    await this.$axios.get(`/search/doc-types`).then(res => {
      commit("setDocs", res.data.data)
    })
  },
  async getEmployees({ state, commit }) {
    const schedule = state.auth.user.acr.user_site.pages.colleague_schedule

    if (schedule.scope === "whole") {
      await this.$axios.get(`/search/employees`).then(res => {
        const employees = window._.cloneDeep(res.data.data)
        commit("setApprovalLines", employees)
        commit("setEmpolyees", employees)
      })
    } else if (schedule.scope === "associated") {
      const ids = window._.map(this.$auth.user.departments, "id")

      await this.$axios.get(`/search/employees`).then(res => {
        commit("setApprovalLines", window._.cloneDeep(res.data.data))

        let userInfo = []

        window._.forEach(res.data.data, data => {
          const check = data.departments.filter(
            row => ids.filter(id => id === row["id"]).length !== 0
          )

          data.departments = check

          if (check.length !== 0) {
            userInfo.push(data)
          }
        })

        commit("setEmpolyees", userInfo)
      })
    }
  },

  async getDepartments({ state, commit }) {
    const schedule = state.auth.user.acr.user_site.pages.colleague_schedule

    if (schedule.scope === "whole") {
      await this.$axios.get(`/search/departments`).then(res => {
        commit("setDepartments", res.data.data)
      })
    } else if (schedule.scope === "associated") {
      const ids = window._.map(this.$auth.user.departments, "id")

      await this.$axios.get(`/search/departments`).then(res => {
        let departInfo = []

        window._.forEach(ids, id => {
          const info = window._.find(res.data.data, {
            id: id
          })

          departInfo.push(info)
        })

        window._.forEach(departInfo, (data, index) => {
          data.parent_id = "#"
          data.position = index
        })

        commit("setDepartments", departInfo)
      })
    }
  },

  async getHolidays({ commit }, year) {
    await this.$axios.get(`/search/holidays?year=${year}`).then(res => {
      commit("setHolidays", res.data)
    })
  },

  async getLoadCheck({ commit }, value) {
    commit("setLoadCheck", value)
  }
}

export const plugins = [
  createPersistedState({
    key: "vuex",
    reducer(val) {
      if (val.auth.loggedIn === false) {
        return {}
      }
      return val
    },
    storage: window.localStorage
  })
]
