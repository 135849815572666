import Vue from 'vue';
import VueSweetalert2 from 'vue-sweetalert2';

Vue.use(VueSweetalert2, {
  "confirmButtonText": "확인",
  "cancelButtonText": "취소"
});
export default ({}, inject) => {
  inject('swal', Vue.swal)
}
