import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7cb28816 = () => interopDefault(import('../pages/alarm.vue' /* webpackChunkName: "pages/alarm" */))
const _5ac48059 = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _415eb12e = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _099aa763 = () => interopDefault(import('../pages/notice.vue' /* webpackChunkName: "pages/notice" */))
const _fe9077d8 = () => interopDefault(import('../pages/sso.vue' /* webpackChunkName: "pages/sso" */))
const _3b935940 = () => interopDefault(import('../pages/workingattitude.vue' /* webpackChunkName: "pages/workingattitude" */))
const _48205fd4 = () => interopDefault(import('../pages/approval/approval.vue' /* webpackChunkName: "pages/approval/approval" */))
const _99cd6d9c = () => interopDefault(import('../pages/approval/drafting.vue' /* webpackChunkName: "pages/approval/drafting" */))
const _7f68430a = () => interopDefault(import('../pages/approval/reference.vue' /* webpackChunkName: "pages/approval/reference" */))
const _da79b094 = () => interopDefault(import('../pages/find/id.vue' /* webpackChunkName: "pages/find/id" */))
const _1e44a6f6 = () => interopDefault(import('../pages/find/password.vue' /* webpackChunkName: "pages/find/password" */))
const _b0ccdff8 = () => interopDefault(import('../pages/find/reset.vue' /* webpackChunkName: "pages/find/reset" */))
const _039d8a0c = () => interopDefault(import('../pages/leave/plan.vue' /* webpackChunkName: "pages/leave/plan" */))
const _70b388d6 = () => interopDefault(import('../pages/leave/status.vue' /* webpackChunkName: "pages/leave/status" */))
const _c1667818 = () => interopDefault(import('../pages/schedule/all.vue' /* webpackChunkName: "pages/schedule/all" */))
const _3f2972ae = () => interopDefault(import('../pages/schedule/my.vue' /* webpackChunkName: "pages/schedule/my" */))
const _738b3730 = () => interopDefault(import('../pages/user/profile.vue' /* webpackChunkName: "pages/user/profile" */))
const _77b70e17 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'active-link',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/alarm",
    component: _7cb28816,
    name: "alarm"
  }, {
    path: "/dashboard",
    component: _5ac48059,
    name: "dashboard"
  }, {
    path: "/login",
    component: _415eb12e,
    name: "login"
  }, {
    path: "/notice",
    component: _099aa763,
    name: "notice"
  }, {
    path: "/sso",
    component: _fe9077d8,
    name: "sso"
  }, {
    path: "/workingattitude",
    component: _3b935940,
    name: "workingattitude"
  }, {
    path: "/approval/approval",
    component: _48205fd4,
    name: "approval-approval"
  }, {
    path: "/approval/drafting",
    component: _99cd6d9c,
    name: "approval-drafting"
  }, {
    path: "/approval/reference",
    component: _7f68430a,
    name: "approval-reference"
  }, {
    path: "/find/id",
    component: _da79b094,
    name: "find-id"
  }, {
    path: "/find/password",
    component: _1e44a6f6,
    name: "find-password"
  }, {
    path: "/find/reset",
    component: _b0ccdff8,
    name: "find-reset"
  }, {
    path: "/leave/plan",
    component: _039d8a0c,
    name: "leave-plan"
  }, {
    path: "/leave/status",
    component: _70b388d6,
    name: "leave-status"
  }, {
    path: "/schedule/all",
    component: _c1667818,
    name: "schedule-all"
  }, {
    path: "/schedule/my",
    component: _3f2972ae,
    name: "schedule-my"
  }, {
    path: "/user/profile",
    component: _738b3730,
    name: "user-profile"
  }, {
    path: "/",
    component: _77b70e17,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
