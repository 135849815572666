import Raven from "raven-js"

export default ({ route, redirect, app }) => {
  if (route.name === "login" && route.path !== "/login") {
    return true
  }

  if (
    route.path !== "/sso" &&
    route.path !== "/login" &&
    /^\/find\//i.test(route.path) === false &&
    /^\/join\//i.test(route.path) === false &&
    !app.$auth.loggedIn
  ) {
    return redirect("/login")
  }

  if (app.$auth.loggedIn) {
    // Sentry Raven Context 추가 정보
    Raven.setUserContext({
      email: app.$auth.user.user.email
    })

    Raven.setExtraContext(app.$auth.$storage._state)
    Raven.setExtraContext(app.$auth.user)

    if (route.path === "/") {
      return redirect("/dashboard")
    }

    if (
      route.path === "/schedule/all" &&
      app.$auth.user.acr.user_site.pages.colleague_schedule.accessible === false
    ) {
      return redirect("/schedule/my")
    }

    if (
      /^\/leave\/plan/i.test(route.path) === true &&
      app.$auth.user.acr.user_site.pages.encourage_leave.accessible === false
    ) {
      return redirect("/leave/status")
    }
  }
}
