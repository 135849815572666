import Vue from 'vue'
import { wrapFunctional } from './utils'

const components = {
  HeaderMenu: () => import('../../components/HeaderMenu.vue' /* webpackChunkName: "components/header-menu" */).then(c => wrapFunctional(c.default || c)),
  LoadingActive: () => import('../../components/LoadingActive.vue' /* webpackChunkName: "components/loading-active" */).then(c => wrapFunctional(c.default || c)),
  Logo: () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c)),
  NaviBar: () => import('../../components/NaviBar.vue' /* webpackChunkName: "components/navi-bar" */).then(c => wrapFunctional(c.default || c)),
  AlarmDetailModal: () => import('../../components/alarm/AlarmDetailModal.vue' /* webpackChunkName: "components/alarm-detail-modal" */).then(c => wrapFunctional(c.default || c)),
  NoticeViewModal: () => import('../../components/notice/NoticeViewModal.vue' /* webpackChunkName: "components/notice-view-modal" */).then(c => wrapFunctional(c.default || c)),
  ApprovalButton: () => import('../../components/approval/ApprovalButton.vue' /* webpackChunkName: "components/approval-button" */).then(c => wrapFunctional(c.default || c)),
  ApprovalLine: () => import('../../components/approval/ApprovalLine.vue' /* webpackChunkName: "components/approval-line" */).then(c => wrapFunctional(c.default || c)),
  ApprovalModal: () => import('../../components/approval/ApprovalModal.vue' /* webpackChunkName: "components/approval-modal" */).then(c => wrapFunctional(c.default || c)),
  ApprovalTableFilter: () => import('../../components/approval/ApprovalTableFilter.vue' /* webpackChunkName: "components/approval-table-filter" */).then(c => wrapFunctional(c.default || c)),
  ApprovalTableFilterFile: () => import('../../components/approval/ApprovalTableFilterFile.vue' /* webpackChunkName: "components/approval-table-filter-file" */).then(c => wrapFunctional(c.default || c)),
  ApprovalTableSimpleFilter: () => import('../../components/approval/ApprovalTableSimpleFilter.vue' /* webpackChunkName: "components/approval-table-simple-filter" */).then(c => wrapFunctional(c.default || c)),
  ApprovalCCList: () => import('../../components/approval/CCList.vue' /* webpackChunkName: "components/approval-c-c-list" */).then(c => wrapFunctional(c.default || c)),
  ApprovalCommuteDescription: () => import('../../components/approval/CommuteDescription.vue' /* webpackChunkName: "components/approval-commute-description" */).then(c => wrapFunctional(c.default || c)),
  ApprovalD001: () => import('../../components/approval/D001.vue' /* webpackChunkName: "components/approval-d001" */).then(c => wrapFunctional(c.default || c)),
  ApprovalD002: () => import('../../components/approval/D002.vue' /* webpackChunkName: "components/approval-d002" */).then(c => wrapFunctional(c.default || c)),
  ApprovalD003: () => import('../../components/approval/D003.vue' /* webpackChunkName: "components/approval-d003" */).then(c => wrapFunctional(c.default || c)),
  ApprovalD004: () => import('../../components/approval/D004.vue' /* webpackChunkName: "components/approval-d004" */).then(c => wrapFunctional(c.default || c)),
  ApprovalD005: () => import('../../components/approval/D005.vue' /* webpackChunkName: "components/approval-d005" */).then(c => wrapFunctional(c.default || c)),
  ApprovalD006: () => import('../../components/approval/D006.vue' /* webpackChunkName: "components/approval-d006" */).then(c => wrapFunctional(c.default || c)),
  ApprovalD008: () => import('../../components/approval/D008.vue' /* webpackChunkName: "components/approval-d008" */).then(c => wrapFunctional(c.default || c)),
  ApprovalD008Item: () => import('../../components/approval/D008Item.vue' /* webpackChunkName: "components/approval-d008-item" */).then(c => wrapFunctional(c.default || c)),
  ApprovalD009: () => import('../../components/approval/D009.vue' /* webpackChunkName: "components/approval-d009" */).then(c => wrapFunctional(c.default || c)),
  ApprovalDocInfo: () => import('../../components/approval/DocInfo.vue' /* webpackChunkName: "components/approval-doc-info" */).then(c => wrapFunctional(c.default || c)),
  LeaveApprovalButton: () => import('../../components/leave/ApprovalButton.vue' /* webpackChunkName: "components/leave-approval-button" */).then(c => wrapFunctional(c.default || c)),
  LeaveApprovalLine: () => import('../../components/leave/ApprovalLine.vue' /* webpackChunkName: "components/leave-approval-line" */).then(c => wrapFunctional(c.default || c)),
  LeaveApprovalPlan: () => import('../../components/leave/ApprovalPlan.vue' /* webpackChunkName: "components/leave-approval-plan" */).then(c => wrapFunctional(c.default || c)),
  LeaveApprovalTableFilterFile: () => import('../../components/leave/ApprovalTableFilterFile.vue' /* webpackChunkName: "components/leave-approval-table-filter-file" */).then(c => wrapFunctional(c.default || c)),
  LeaveApprovalTableSimpleFilter: () => import('../../components/leave/ApprovalTableSimpleFilter.vue' /* webpackChunkName: "components/leave-approval-table-simple-filter" */).then(c => wrapFunctional(c.default || c)),
  LeaveDocInfo: () => import('../../components/leave/DocInfo.vue' /* webpackChunkName: "components/leave-doc-info" */).then(c => wrapFunctional(c.default || c)),
  LeaveDocItem: () => import('../../components/leave/DocItem.vue' /* webpackChunkName: "components/leave-doc-item" */).then(c => wrapFunctional(c.default || c)),
  LeaveDocViewModal: () => import('../../components/leave/DocViewModal.vue' /* webpackChunkName: "components/leave-doc-view-modal" */).then(c => wrapFunctional(c.default || c)),
  LeaveMyPlan: () => import('../../components/leave/MyPlan.vue' /* webpackChunkName: "components/leave-my-plan" */).then(c => wrapFunctional(c.default || c)),
  LeaveReferencePlan: () => import('../../components/leave/ReferencePlan.vue' /* webpackChunkName: "components/leave-reference-plan" */).then(c => wrapFunctional(c.default || c)),
  LeaveReferenceTableFilterFile: () => import('../../components/leave/ReferenceTableFilterFile.vue' /* webpackChunkName: "components/leave-reference-table-filter-file" */).then(c => wrapFunctional(c.default || c)),
  LeaveReferenceTableSimpleFilter: () => import('../../components/leave/ReferenceTableSimpleFilter.vue' /* webpackChunkName: "components/leave-reference-table-simple-filter" */).then(c => wrapFunctional(c.default || c)),
  ScheduleDaySchedule: () => import('../../components/schedule/DaySchedule.vue' /* webpackChunkName: "components/schedule-day-schedule" */).then(c => wrapFunctional(c.default || c)),
  ScheduleMonthDetailModal: () => import('../../components/schedule/MonthDetailModal.vue' /* webpackChunkName: "components/schedule-month-detail-modal" */).then(c => wrapFunctional(c.default || c)),
  ScheduleMonthSchedule: () => import('../../components/schedule/MonthSchedule.vue' /* webpackChunkName: "components/schedule-month-schedule" */).then(c => wrapFunctional(c.default || c)),
  ScheduleMyMonthModal: () => import('../../components/schedule/MyMonthModal.vue' /* webpackChunkName: "components/schedule-my-month-modal" */).then(c => wrapFunctional(c.default || c)),
  ScheduleDetailModal: () => import('../../components/schedule/ScheduleDetailModal.vue' /* webpackChunkName: "components/schedule-detail-modal" */).then(c => wrapFunctional(c.default || c)),
  ScheduleWeekSchedule: () => import('../../components/schedule/WeekSchedule.vue' /* webpackChunkName: "components/schedule-week-schedule" */).then(c => wrapFunctional(c.default || c)),
  UserPasswordChangeModal: () => import('../../components/user/PasswordChangeModal.vue' /* webpackChunkName: "components/user-password-change-modal" */).then(c => wrapFunctional(c.default || c)),
  UserProfile: () => import('../../components/user/Profile.vue' /* webpackChunkName: "components/user-profile" */).then(c => wrapFunctional(c.default || c)),
  UserProfileGroup: () => import('../../components/user/ProfileGroup.vue' /* webpackChunkName: "components/user-profile-group" */).then(c => wrapFunctional(c.default || c)),
  WorkingattitudeWorkingCommute: () => import('../../components/workingattitude/WorkingCommute.vue' /* webpackChunkName: "components/workingattitude-working-commute" */).then(c => wrapFunctional(c.default || c))
}

for (const name in components) {
  Vue.component(name, components[name])
  Vue.component('Lazy' + name, components[name])
}
