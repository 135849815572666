import Vue from "vue"

Vue.mixin({
  methods: {
    beforePageChange(to, from, next) {
      if (this.change === true) {
        Vue.swal({
          html:
            "<h2 class=\"font-icon icon-unauth color-danger mb-2\"></h2>저장하지 않은 변경사항이 있습니다.<br/>적용하려면 하기 '변경내용 적용'을 클릭하세요.",
          width: 500,
          showCancelButton: true,
          confirmButtonText: "변경내용 적용"
        }).then(result => {
          if (result.value) {
            this.submit()
          } else {
            next()
          }
        })
      } else {
        next()
      }
    },
    beforeComponentChange(to, from, next) {
      if (this.$refs.component.change === true) {
        Vue.swal({
          html:
            "<h2 class=\"font-icon icon-unauth color-danger mb-2\"></h2>저장하지 않은 변경사항이 있습니다.<br/>적용하려면 하기 '변경내용 적용'을 클릭하세요.",
          width: 500,
          showCancelButton: true,
          confirmButtonText: "변경내용 적용"
        }).then(result => {
          if (result.value) {
            this.$refs.component.submit()
          } else {
            next()
          }
        })
      } else {
        next()
      }
    }
  }
})
