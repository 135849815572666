import Vue from "vue"
const mapKeysDeep = require("map-keys-deep-lodash")

Vue.mixin({
  watch: {
    treeData: function() {
      this.treeLoad()
    },
    treeKeyword: function() {
      this.treeSearch()
    }
  },
  methods: {
    mapKey(data) {
      let list = mapKeysDeep(data, (value, key) => {
        if (key === "name") {
          return "text"
        } else if (key === "parent_id") {
          return "parent"
        }

        return key
      })

      return list
    },
    typing: function(e) {
      this.treeKeyword = e.target.value
    },
    treeLayer() {
      this.treeVisible = !this.treeVisible
      if (!this.treeVisible) {
        this.treeKeyword = ""
      } else {
        setTimeout(() => {
          window.$("#treeKeyword").focus()
        }, 200)
      }
    },
    treeAway: function() {
      this.treeVisible = false
      this.treeKeyword = ""
    },
    treeSearch: window._.debounce(function() {
      if (window.$("#tree").text() !== "등록된 내역이 없습니다") {
        window
          .$("#tree")
          .jstree(true)
          .search(this.treeKeyword)
      }
    }, 250),
    treeLoad: function() {
      if (this.jsTree !== null) {
        this.jsTree.jstree("destroy").empty()
      }

      this.jsTree = window
        .$("#tree")
        .jstree({
          core: {
            check_callback: function(op, node, parent, position, more) {
              if (op === "move_node" && more && more.core) {
                if (parent.type === "#") {
                  return false
                }
              }
            },
            data: this.treeData,
            themes: {
              ellipsis: true
            }
          },
          sort: function(a, b) {
            const a_node = this.get_node(a)
            const b_node = this.get_node(b)

            if (a_node.type !== b_node.type) {
              return /^\d{0,}user_/g.test(a) ? -1 : 1
            }
          },
          search: {
            case_insensitive: true,
            show_only_matches: true,
            show_only_matches_children: true,
            search_callback: (str, node) => {
              if (
                this.treeUserSearch !== undefined &&
                this.treeUserSearch === true
              ) {
                const target = node.original.userList.filter(userName => {
                  return userName.toLowerCase().includes(str.toLowerCase())
                })

                return (
                  node.text.toLowerCase().includes(str.toLowerCase()) ||
                  target.length !== 0
                )
              } else {
                return node.text.toLowerCase().includes(str.toLowerCase())
              }
            }
          },
          types: {
            company: {
              icon: "font-icon icon-building"
            },
            department: {
              icon: "font-icon icon-folder_solid"
            },
            user: {
              icon: "font-icon icon-user_solid"
            }
          },
          plugins: ["search", "types", "sort"]
        })
        .bind("ready.jstree", () => {
          let id =
            this.treeSelectData["rawId"] === ""
              ? this.treeData[0].id
              : this.treeSelectData["rawId"]

          window.$("#tree").jstree("select_node", id)

          this.treeKeyword = ""
        })
        .on("loaded.jstree", () => {
          window.$("#tree").jstree("open_node", this.treeData[0].id)
        })
        .on("select_node.jstree", (evt, data) => {
          if (
            this.treeSelectNone !== undefined &&
            this.treeSelectNone === true
          ) {
            this.treeSelectData.id = data.node.id
            this.treeSelectData.type = data.node.type
            this.treeSelectData.rawId = data.node.id
            this.treeSelectData.children = data.node.children_d
          } else {
            this.treeSelectData = {
              id: data.node.id,
              type: data.node.type,
              rawId: data.node.id,
              children: data.node.children_d
            }
          }
          this.targetIcon = data.node.icon
          const name =
            data.node.text.length > 15
              ? data.node.text.substr(0, 15) + "…"
              : data.node.text
          this.targetName = name

          this.treeVisible = false
          this.treeKeyword = ""
        })
    }
  }
})
