import Vue from "vue"
import moment from "moment"

Vue.mixin({
  methods: {
    editAction() {
      window.$(`#${this.tableId}`).on("click", "button.edit", event => {
        const closestRow = window.$(event.currentTarget).parents("tr")
        const row = this.dataTable.row(closestRow).data()

        this.formData = window._.cloneDeep(row)
        this.formData.mod = true

        window.$(`#${this.$refs.modal.modal_id}`).modal({
          backdrop: "static"
        })
      })
    },
    profile(data, type, row) {
      let departmnet_name = ""
      let path = ""
      let rank = ""
      let status = ""

      if (row.departments.length !== 0) {
        departmnet_name = row.departments[0].name
        path = row.departments[0].path
        rank = row.departments[0].rank
      }

      // 오더링을 위한 Dummy Data
      let field = `<div class="d-flex align-items-center"><span class="d-none">${row.name}</span>`

      if (row.type === "pregnancy") {
        status =
          '<em class="font-icon icon-user_heart_solid color-danger-light" title="임산부"></em>'
      } else if (row.status === "absence") {
        status =
          '<em class="font-icon icon-time font-secondary2" title="휴직중"></em>'
      } else if (row.status === "parental") {
        status =
          '<em class="font-icon icon-time color-danger-light" title="육아휴직중"></em>'
      } else if (row.status === "resignation") {
        status =
          '<em class="font-icon icon-user_minus_solid font-secondary2" title="퇴사자"></em>'
      }

      if (row.image !== null && row.image !== undefined) {
        field += `<div class="profile"><div class="profile-img"><img width="40" src="${row.image.url}"></div></div>`
      } else {
        field += `<div class="profile"><div class="profile-img"><span>${row.name}</span></div></div>`
      }

      field += `<div class="user-info mt-1"><div title="${row.name}">${
        type === "export" ? row.name : this.subStr(row.name, 5)
      } ${rank} (${row.email})${status}</div>`
      field += `<div class="font-secondary"><span class="small" title="${path}">${
        type === "export" ? departmnet_name : this.subStr(departmnet_name, 15)
      }</span></div></div></div>`

      return field
    },
    profileSimple(data, type, row) {
      let name = ""
      let email = ""
      let image = ""
      let departmnet_name = ""
      let path = ""
      let rank = ""

      if (row.department !== null) {
        path = row.department.path
        rank = row.department.rank
      }

      if (row.user !== undefined) {
        name = row.user.name
        email = row.user.email
        departmnet_name = row.user.departments[0].name
        path = row.user.departments[0].path
        rank = row.user.departments[0].rank

        if (row.user.image !== null && row.user.image !== undefined) {
          image = row.user.image.url
        }
      } else {
        name = row.name
        email = row.email

        if (row.image !== null && row.image !== undefined) {
          image = row.image.url
        }
      }

      // 오더링을 위한 Dummy Data
      let field = `<div class="d-flex align-items-center"><span class="d-none">${name}</span>`

      if (image !== "") {
        field += `<div class="profile"><div class="profile-img"><img src="${image}"></div></div>`
      } else {
        field += `<div class="profile"><div class="profile-img"><span>${name}</span></div></div>`
      }

      field += `<div class="user-info mt-1"><div title="${name}">${this.subStr(
        name,
        5
      )} ${rank} (${email})</div>`
      field += `<div class="font-secondary"><span class="small" title="${path}">${this.subStr(
        departmnet_name,
        15
      )}</span></div></div></div>`

      return field
    },
    exportField(data, row, target) {
      if (data !== null) {
        return row === target
          ? data
              .replace(/<span class="d-none">(.+?)<\/span>/gi, "") // datatable ordering data remove
              .replace(/<span>(.+?)<\/span>/gi, "") // first name remove
              .replace(/title="(.+?)"/gi, "") // title remove (부서 > 로 인해 정규식 동작을 위해 처리)
              .replace(/\)/, ")\n") // br 개행처리
              .replace(/<\/?[^>]+(>|$)/g, "") // html remove
          : isNaN(data)
          ? data.replace(/<\/?[^>]+(>|$)/g, "")
          : data
      }
    },
    subStr(string, length) {
      string =
        string !== null &&
        string !== undefined &&
        string.length > window._.parseInt(length)
          ? string.substr(0, length) + "…"
          : string

      return string
    },
    dateHourFormat(date) {
      if (date !== undefined && date !== null) {
        return moment(date).format("HH:mm")
      }
    },
    secondDisplay(second) {
      let text = "-"

      if (!isNaN(second) && second !== 0) {
        text = `${
          second / 60 / 60 >= 1 ? Math.floor(second / 60 / 60) + "시간" : ""
        }${second % 3600 !== 0 ? Math.floor((second % 3600) / 60) + "분" : ""}`
      }

      return text
    },
    minuteDisplay(minute) {
      let text = "-"

      if (!isNaN(minute) && minute !== 0) {
        text = `${minute / 60 >= 1 ? Math.floor(minute / 60) + "시간" : ""}${
          minute % 60 !== 0 ? Math.floor(minute % 60) + "분" : ""
        }`
      }

      return text
    },
    dayduty(holiday = null) {
      if (holiday !== null && holiday.holiday === true) {
        if (holiday.type === "primary") {
          return "primary-holiday"
        } else if (holiday.type === "supplement") {
          return "supplement-holiday"
        } else {
          return "anniversary-holiday"
        }
      }
    },
    displayDate: function(val) {
      return moment(val).format("MM-DD")
    },
    displayDay: function(val) {
      return moment(val).format("ddd")
    },
    workDisplay(data, row) {
      if (row.status !== undefined && row.status.length >= 1) {
        let text = []

        window._.forEach(row.status, status => {
          if (status === "business-trip") {
            text.push(
              '<span class="badge badge-secondary-bgsblue badge_table">출장</span>'
            )
          } else if (status === "local-commute-in") {
            text.push(
              '<span class="badge badge-secondary-bgsblue badge_table">현지출근</span>'
            )
          } else if (status === "local-commute-out") {
            text.push(
              '<span class="badge badge-secondary-bgsblue badge_table">현지퇴근</span>'
            )
          } else if (status === "at-home-work") {
            text.push(
              '<span class="badge badge-secondary-bgsblue badge_table">재택근무</span>'
            )
          } else if (status === "leave") {
            text.push(
              '<span class="badge badge-green-bglgreen badge_table"><em class="font-icon icon-leave"></em>휴가</span>'
            )
          } else if (status === "half-leave" || status === "quarter-leave") {
            text.push(
              '<span class="badge badge-green-bglgreen badge_table"><em class="font-icon icon-leave"></em>휴가(일부)</span>'
            )
          } else if (status === "leave-na") {
            text.push(
              '<span class="badge badge-green-bglgreen badge_table"><em class="font-icon icon-leave"></em>휴가(불)</span>'
            )
          } else if (
            status === "half-leave-na" ||
            status === "quarter-leave-na"
          ) {
            text.push(
              '<span class="badge badge-green-bglgreen badge_table"><em class="font-icon icon-leave"></em>휴가(불/일부)</span>'
            )
          } else if (status === "anniversary-holiday") {
            text.push(
              `<span class="badge badge-green-bglgreen badge_table" title="${
                //무급 휴일 class="font-secondary2"에서 변경
                row.holiday.name
              }"><em class="font-icon icon-public_holiday"></em>${this.subStr(
                row.holiday.name,
                8
              )}</span>`
            )
          } else if (
            status === "primary-holiday" ||
            status === "supplement-holiday"
          ) {
            if (row.holiday.duty === true) {
              text.push(
                '<span class="badge badge-green-bglgreen badge_table">휴무</span>'
              )
            } else {
              text.push(
                `<span class="badge badge-green-bglgreen badge_table">휴일</span>`
              )
            }
          }
        })

        if (text.length === 0) {
          text.push("-")
        }

        data = `${text.join("/")}`
      } else {
        if (row.working_hour_schedule[0].start_at === null) {
          data =
            '<span class="badge badge-danger-bggrey badge_table">미등록</span>'
        } else {
          data =
            '<span class="badge badge-black-bggrey badge_table">근무</span>'
        }
      }

      return data
    },
    workInfo(data, row) {
      let field = "-"

      let info = []

      if (
        row.working_hour_schedule.length !== 0 &&
        row.working_hour_system_type !== null
      ) {
        window._.forEach(row.working_hour_schedule, val => {
          if (
            val.start_at !== null &&
            val.to_start_at !== null &&
            val.end_at !== null &&
            val.to_end_at !== null
          ) {
            info.push(
              `${this.dateHourFormat(val.start_at)}/${this.dateHourFormat(
                val.to_start_at
              )} ~ <br/>${this.dateHourFormat(
                val.end_at
              )}/${this.dateHourFormat(val.to_end_at)}${
                val.date !== moment(val.end_at).format("YYYY-MM-DD") ||
                val.date !== moment(val.to_end_at).format("YYYY-MM-DD")
                  ? "(익일)"
                  : ""
              }`
            )
          } else if (val.start_at !== null && val.end_at !== null) {
            info.push(
              `${this.dateHourFormat(val.start_at)} ~ ${this.dateHourFormat(
                val.end_at
              )}${
                val.date !== moment(val.end_at).format("YYYY-MM-DD")
                  ? "(익일)"
                  : ""
              }`
            )
          }
        })

        field = info.join("<br/>")
      }

      return field
    },
    addWorkInfo(data, row) {
      let field = "-"

      let info = []

      if (row.overtimes.length !== 0 && row.working_hour_system_type !== null) {
        window._.forEach(row.overtimes, val => {
          if (val.start_at !== null && val.end_at !== null) {
            info.push(
              `${this.dateHourFormat(val.start_at)} ~ ${this.dateHourFormat(
                val.end_at
              )}${
                val.date !== moment(val.end_at).format("YYYY-MM-DD")
                  ? "(익일)"
                  : ""
              }`
            )
          }
        })

        field = info.join("<br/>")
      }

      return field
    },
    fixedWokingDisplay(data, row) {
      let field = "-"

      if (row.working_hour_schedule.length !== 0) {
        field = this.minuteDisplay(
          this.sumBy(row.working_hour_schedule, "fixed_working_min")
        )
      }

      return field
    },
    addWokingDisplay(data, row) {
      let field = "-"

      if (row.working_hour_schedule.length !== 0) {
        field = this.minuteDisplay(
          this.sumBy(row.working_hour_schedule, "additional_working_min")
        )
      }

      return field
    },
    breakDisplay(data, row) {
      let field = "-"

      if (row.working_hour_schedule.length !== 0) {
        field = this.minuteDisplay(
          this.sumBy(row.working_hour_schedule, "break_min")
        )
      }

      return field
    },
    daydutyColor(key, date = null) {
      let duty = this.$store.state.holidays

      if (duty.holiday.day === key && duty.holiday.holiday === true) {
        return "color-danger"
      }

      if (
        duty.regular_holiday.day === key &&
        duty.regular_holiday.holiday === true
      ) {
        return "color-officeblue"
      }

      if (
        date !== null &&
        window._.findKey(duty.data, { date: date }) !== undefined
      ) {
        const target = window._.find(duty.data, { date: date })

        if (target.holiday === true) {
          return "color-danger"
        }
      }
    },
    sumBy(obj, key) {
      return window._.sumBy(obj, key)
    }
  }
})
